import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const NavModal = ({ onClose }) => {
  const modalRef = useRef();

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     const navbar = document.getElementById("navbar");

  //     if (
  //       modalRef.current &&
  //       !modalRef.current.contains(event.target) &&
  //       navbar &&
  //       !navbar.contains(event.target)
  //     ) {
  //       onClose();
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [onClose]);

  return (
    <div
      ref={modalRef}
      className="w-full absolute delay-100 transition-all translate-x-0 translate-y-0 shadow-2xl bg-white"
    >
      <hr />
      <div className="grid grid-cols-1 md:grid-cols-4 content-center justify-items-center md:mx-28">
        <div className="text-start flex flex-col gap-0.5 md:gap-3 w-full px-1 bg-gray-700 text-white">
          <div className="pt-5">
            <ul className="flex items-center justify-center">
              <li class="mr-3">
                <a
                  class="inline-block border border-white rounded py-2 px-4 font-semibold"
                  href="#"
                >
                  Sign In
                </a>
              </li>
              <li class="mr-3">
                <a
                  class="inline-block border border-blue-500 rounded py-2 px-4 bg-blue-500 text-white font-semibold"
                  href="#"
                >
                  Try it free
                </a>
              </li>
            </ul>
          </div>
          <h1 className="uppercase font-bold md:border-b-2 md:text-green-500 md:border-green-100 px-3 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-0.5 md:gap-3 w-full px-1 bg-gray-700 text-white">
          <h1 className="uppercase font-bold md:border-b-2 md:text-green-500 md:border-green-100 px-3 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-0.5 md:gap-3 w-full px-1 bg-gray-700 text-white">
          <h1 className="uppercase font-bold md:border-b-2 md:text-green-500 md:border-green-100 px-3 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-0.5 md:gap-3 w-full px-1 bg-gray-700 text-white">
          <h1 className="uppercase font-bold md:border-b-2 md:text-green-500 md:border-green-100 px-3 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-0.5 md:gap-3 w-full px-1 bg-gray-700 text-white">
          <h1 className="uppercase font-bold md:border-b-2 md:text-green-500 md:border-green-100 px-3 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-0.5 md:gap-3 w-full px-1 bg-gray-700 text-white">
          <h1 className="uppercase font-bold md:border-b-2 md:text-green-500 md:border-green-100 px-3 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-0.5 md:gap-3 w-full px-1 bg-gray-700 text-white">
          <h1 className="uppercase font-bold md:border-b-2 md:text-green-500 md:border-green-100 px-3 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-0.5 md:gap-3 w-full px-1 bg-gray-700 text-white">
          <h1 className="uppercase font-bold md:border-b-2 md:text-green-500 md:border-green-100 px-3 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
          <p className="text-sm bg-gray-800 py-4 px-5">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-1 py-5 w-full px-1 bg-gray-700 text-white">
          <Link
            to="/pricing-plan"
            className="uppercase font-bold bg-gray-800 md:border-b-2  text-center px-3 py-3 pr-40"
          >
            Pricing
          </Link>
          <Link
            to="/contact"
            className="uppercase font-bold bg-gray-800 md:border-b-2  text-center px-3 py-3 pr-40"
          >
            Finance
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavModal;
