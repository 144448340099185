import React from "react";
import ContactForm from "./ContactForm";
import DirectContacts from "./DirectContacts";
import Offices from "./Offices";

const Contact = () => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-7 my-20 px-10">
        <ContactForm />
        <DirectContacts />
      </div>
      <Offices />
    </div>
  );
};

export default Contact;
