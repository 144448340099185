import React from "react";
import odo from "../../assets/hq720.jpg";
import { FaArrowUp } from "react-icons/fa6";

const Start = () => {
  return (
    <div className="my-20">
      <img src={odo} alt="odo" className="mx-auto mb-10" />
      <div className="flex justify-center items-center gap-6">
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Start now - It's free
        </button>
      </div>
      <FaArrowUp className="mx-auto my-4 text-2xl" />
      <p>
        No credit card required <br /> Instant access
      </p>
    </div>
  );
};

export default Start;
