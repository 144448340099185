import React from "react";

const Description = () => {
  return (
    <div className="bg-gray-100 pb-10">
      <div className="bg-gray-100 flex flex-col gap-5 p-7 rounded-lg w-full md:w-4/5 lg:w-3/5 mx-auto">
        <h1 className="text-lg md:text-2xl lg:text-3xl font-semibold text-gray-600 text-center">
          Imagine a vast collection of business apps at your disposal.
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl text-gray-500 text-center">
          Got something to improve? There is an app for that. No complexity, no
          cost, just a one-click install.
        </p>
        <p className="text-lg md:text-xl lg:text-2xl text-gray-500 text-center">
          Each app simplifies a process and empowers more people. Imagine the
          impact when everyone gets the right tool for the job, with perfect
          integration.
        </p>
      </div>
    </div>
  );
};

export default Description;
