import React from "react";
import odo from "../../assets/hq720.jpg";

const Banner = () => {
  return (
    <div>
      <img src={odo} alt="odo" className="mx-auto mb-10 pt-10" />
      <div className="flex flex-wrap justify-center items-center gap-6">
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Start now
        </button>
        <button class="bg-gray-100 hover:bg-gray-200 text-blue-700 font-bold py-2 px-4 rounded">
          Schedule a demo
        </button>
      </div>
    </div>
  );
};

export default Banner;
