import React from "react";

const Quality = () => {
  return (
    <div className="bg-gray-100 py-20">
      <h1 className="text-center font-extrabold text-3xl md:text-5xl w-4/5 mx-auto my-20">
        Level up your quality of work
      </h1>
      <div>
        <iframe
          src="https://download.odoocdn.com/videos/odoo_com/video_homepage.webm"
          frameborder="0"
          //   width={100}
          allow="autoplay"
          allowFullScreen
          className="w-3/4 mx-auto h-96"
        ></iframe>
      </div>
    </div>
  );
};

export default Quality;
