import React, { useState } from "react";

const faqs = [
  {
    id: 1,
    question: "What are the advantages of your service?",
    answer:
      "If you go over your organisations or user limit, a member of the team will reach out about bespoke pricing...",
  },
  {
    id: 2,
    question:
      "Are there any fees or commissions in addition to the monthly subscription?",
    answer:
      "If you go over your organisations or user limit, a member of the team will reach out about bespoke pricing...",
  },
  // Add more FAQs here
];

function FAQAccordion() {
  return (
    <div className="divide-y divide-slate-200 border-2 rounded-lg">
      {faqs.map((faq) => (
        <AccordionItem key={faq.id} faq={faq} />
      ))}
    </div>
  );
}

function AccordionItem({ faq }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="py-2">
      <h2>
        <button
          id={`faqs-title-${faq.id}`}
          type="button"
          className="text-xl md:text-2xl flex items-center justify-between w-full text-left text-gray-600 py-3 px-7"
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-controls={`faqs-text-${faq.id}`}
        >
          <span>{faq.question}</span>
          {/* SVG icons */}
        </button>
      </h2>
      <div
        id={`faqs-text-${faq.id}`}
        role="region"
        aria-labelledby={`faqs-title-${faq.id}`}
        className={`grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
          expanded ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="overflow-hidden">
          <p className="p-4 text-base md:text-lg">{faq.answer}</p>
        </div>
      </div>
    </div>
  );
}

export default FAQAccordion;
