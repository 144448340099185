import React from "react";
import FAQAccordion from "./FaqAccordion";

const Questions = () => {
  return (
    <div className="md:w-4/5 md:mx-auto my-20 mx-5">
      <h1 className="text-start font-extrabold text-5xl mb-10">
        Any Questions?
      </h1>
      <p className="text-start">
        If the answer to your question is not on this page, please contact our{" "}
        <span className="text-teal-500">
          <a href="#">Account Managers</a>
        </span>
      </p>

      <div className="relative font-inter antialiased my-10">
        <FAQAccordion />
      </div>
    </div>
  );
};

export default Questions;
