import React from "react";

const ContactForm = () => {
  return (
    <div className="p-4 md:p-0 md:col-span-2">
      <div className="mb-6">
        <h1 className="text-start font-semibold text-4xl md:font-bold md:text-5xl">
          Contact us
        </h1>

        <p className="text-start text-gray-600 text-lg md:my-4 mt-2">
          We are here to help.
        </p>
      </div>

      <form className="w-full">
        <div className="md:flex items-center mb-5">
          <label
            for="email"
            className="block mb-2 md:text-lg text-base text-gray-600 w-56 text-start"
          >
            Name *
          </label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-3 w-full"
            required
          />
        </div>
        <div className="md:flex items-center mb-5">
          <label
            for="email"
            className="block mb-2 md:text-lg text-base text-gray-600 w-56 text-start"
          >
            Phone Number
          </label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-3 w-full"
            defaultValue="+880"
            required
          />
        </div>
        <div className="md:flex items-center mb-5">
          <label
            for="email"
            className="block mb-2 md:text-lg text-base text-gray-600 w-56 text-start"
          >
            Email *
          </label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-3 w-full"
            required
          />
        </div>
        <div className="md:flex items-center mb-5">
          <label
            for="email"
            className="block mb-2 md:text-lg text-base text-gray-600 w-56 text-start"
          >
            Company
          </label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-3 w-full"
            required
          />
        </div>
        <div className="md:flex items-center mb-5">
          <label
            for="email"
            className="block mb-2 md:text-lg text-base text-gray-600 w-56 text-start"
          >
            Subject *
          </label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-3 w-full"
            required
          />
        </div>
        <div className="md:flex items-center mb-5">
          <label
            for="email"
            className="block mb-2 md:text-lg text-base text-gray-600 w-56 text-start"
          >
            Your company size :
          </label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-3 w-full"
            required
          />
        </div>
        <div className="md:flex justify-start mb-5">
          <label
            for="email"
            className="block mb-2 text-lg text-gray-600 w-56 text-start"
          >
            Question *
          </label>
          <textarea
            id="message"
            rows="8"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          ></textarea>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center relative md:-left-40"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
