import React from "react";
import sales from "../../assets/icon (1).svg";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const ScheduleDemo = () => {
  const elements = Array.from({ length: 10 }, (_, i) => i);
  return (
    <div className="bg-gray-100 py-20">
      <h1 className="  px-5 md:px-0">
        The Standard and Custom plans include all apps for a single fee:
      </h1>
      <div className="flex flex-wrap justify-center gap-10 w-4/5 mx-auto my-5">
        {elements.map((i) => (
          <div className="flex flex-col justify-center items-center">
            <img
              src={sales}
              alt="sales"
              className=" shadow-xl rounded-md p-3 w-full"
            />
            <h3 className="font-semibold my-3">sales</h3>
          </div>
        ))}
      </div>
      <button class="flex items-center justify-center gap-2 text-blue-400 font-semibold text-3xl mx-auto mb-16">
        <span>And many more</span>
        <MdOutlineKeyboardDoubleArrowRight className="text-2xl" />
      </button>
      <div className="flex flex-col gap-6 mb-16  px-5 md:px-0">
        <p className="text-gray-600">
          All our plans include unlimited{" "}
          <span className="font-bold">support, hosting</span> and{" "}
          <span className="font-bold">maintenance</span>.
          <br />
          With no hidden costs, no limit on features or data: enjoy real
          transparency!
        </p>
        <p className="text-gray-600">
          (*) The discount is valid for 12 months, for initial users ordered.{" "}
          <br /> (**) Cost for Odoo.sh hosting not included.
        </p>
      </div>
      <button class="bg-gray-200 hover:bg-gray-300 text-xl font-bold py-4 px-10 rounded text-blue-500">
        Schedule a demo
      </button>
    </div>
  );
};

export default ScheduleDemo;
