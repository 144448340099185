import React from "react";
import set_up from "../../assets/set_up.webp";
import gant_graph from "../../assets/gant_graph.svg";

const Features = () => {
  return (
    <div className="bg-gray-100 py-20 rounded-tl-[10rem] rounded-br-[10rem] my-20">
      <h1 className="text-start font-extrabold text-5xl w-4/5 mx-auto">
        Enterprise software done right.
      </h1>
      <div className=" lg:w-4/5 mx-auto py-10 flex md:flex-row flex-col gap-10 px-10 lg:px-0">
        <div className="flex flex-col gap-10 md:w-3/5">
          <div className=" flex flex-col gap-5 bg-white p-7 rounded-lg">
            <h1 className="text-2xl font-semibold text-gray-600 text-start">
              Open source
            </h1>
            <p className="text-xl text-gray-600 text-start">
              Behind the technology is a community of 100k+ developers
              collaborating worldwide. We're united by the spirit of open
              source, and a common vision: "to transform companies, empower
              employees".
            </p>
            <div>
              <p className="text-xl text-gray-600 text-start">
                Odoo is available in two editions:
              </p>
              <p className="text-xl text-gray-600 text-start">
                <span className="text-black font-semibold">• Community:</span>{" "}
                Open Source, 100% free.
              </p>
              <p className="text-xl text-gray-600 text-start">
                <span className="text-black font-semibold">• Enterprise:</span>{" "}
                extra apps, infrastructure and professional services.
              </p>
            </div>
            <div className="text-start">
              <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Compare Editions
              </button>
            </div>
          </div>
          <div className=" flex flex-col gap-5 bg-white px-10 pt-10 rounded-lg">
            <h1 className="text-2xl font-semibold text-gray-600 text-start">
              Highly customizable
            </h1>
            <p className="text-xl text-gray-600 text-start">
              Use Odoo Studio to automate actions, design custom screens, custom
              reports, or web hooks.
            </p>
            <img
              src={set_up}
              alt="set_up"
              className="p-3 shadow-inner rounded-x-lg rounded-t-lg w-full"
            />
          </div>
          <div className=" flex flex-col gap-5 bg-white p-7 rounded-lg">
            <h1 className="text-2xl font-semibold text-gray-600 text-start">
              40k+ community apps
            </h1>
            <p className="text-xl text-gray-600 text-start">
              Thanks to it's open source development model, Odoo became the
              world's largest business apps store. Imagine getting an app for
              every business needs.
            </p>
            <div className="text-start">
              <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Browse Community Apps
              </button>
            </div>
          </div>
          <div className=" flex flex-col gap-5 bg-white p-7 rounded-lg">
            <h1 className="text-2xl font-semibold text-gray-600 text-start">
              No corporate bullsh*t
            </h1>
            <p className="text-xl text-gray-600 text-start">
              "With most systems, you get 70% of what you hoped. With Odoo, you
              get more than what you expected. You, guys, will transform the
              market."
              <br />- Anonymous competitor
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-10 md:w-2/5">
          <div className=" flex flex-col gap-5 bg-white p-7 rounded-lg">
            <h1 className="text-2xl font-semibold text-gray-600 text-start">
              No vendor lock-in
            </h1>
            <p className="text-xl text-gray-600 text-start">
              No proprietary data format, just PostgreSQL: you own your data. No
              software lock-in: you get the source code, GitHub access, and the
              flexibility to host on our infrastructure, or on premise.
            </p>
            <div className="text-start">
              <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Follow us on Github
              </button>
            </div>
          </div>
          <div className=" flex flex-col gap-5 bg-white p-7 rounded-lg">
            <h1 className="text-2xl font-semibold text-gray-600 text-start">
              Fair pricing
            </h1>
            <p className="text-xl text-gray-600 text-start">
              No usage-based pricing, no feature upselling, no long term
              contracts, no hosting limits, no surprises... just a single price
              per user - all inclusive.
            </p>
            <div className="text-start">
              <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                View Pricing
              </button>
            </div>
          </div>
          <div className=" flex flex-col gap-5 bg-white px-7 pt-7 rounded-lg">
            <h1 className="text-2xl font-semibold text-gray-600 text-start">
              A unique value proposition
            </h1>
            <img
              src={gant_graph}
              alt="gant_graph"
              className="p-3 shadow-inner rounded-x-lg rounded-t-lg w-full h-[27rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
