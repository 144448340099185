import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import PricePlane from "./routes/PricePlane";
import ContactUs from "./routes/ContactUs";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route path="/pricing-plan" element={<PricePlane />} />
      </Routes>
      <Routes>
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
