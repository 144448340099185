import React, { useState } from "react";
import { FaToggleOff } from "react-icons/fa6";
import { FaToggleOn } from "react-icons/fa6";
import PlanCards from "./PlanCards";
import ScheduleDemo from "./ScheduleDemo";
import Questions from "./Questions";

const PricingPlan = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="my-20">
      <h1 className="text-center font-extrabold text-5xl w-4/5 mx-auto mb-10">
        You are not dreaming!
      </h1>
      <div className="flex items-center justify-center gap-4">
        <h1 className="text-xl font-bold text-gray-600">Yearly</h1>
        {toggle ? (
          <FaToggleOff
            className="text-5xl text-blue-400"
            onClick={() => setToggle(!toggle)}
          />
        ) : (
          <FaToggleOn
            className="text-5xl text-blue-400"
            onClick={() => setToggle(!toggle)}
          />
        )}
        <h1 className="text-xl font-bold text-gray-600">Monthly</h1>
      </div>
      <PlanCards />
      <ScheduleDemo />
      <Questions />
    </div>
  );
};

export default PricingPlan;
