import React from "react";
import Banner from "../components/Banner/Banner";
import Service from "../components/Services/Service";
import Description from "../components/Description/Description";
import Productivity from "../components/Productivity/Productivity";
import Tech from "../components/Techs/Tech";
import Features from "../components/Features/Features";
import User from "../components/Users/User";
import Start from "../components/Start/Start";
import Layout from "../layouts/Layout";
import Quality from "../components/Quality/Quality";
// import Testt from "../components/Productivity/Testt";

const Home = () => {
  return (
    <div>
      <Layout>
        <Banner />
        <Service />
        <Description />
        <Quality />
        <Productivity />
        {/* <Testt /> */}
        <Tech />
        <Features />
        <User />
        <Start />
      </Layout>
    </div>
  );
};

export default Home;
