import React from "react";

const PlanCards = () => {
  return (
    <div className="bg-gray-100 rounded-t-full">
      <div className="grid grid-cols-1 lg:grid-cols-3 w-4/5 mx-auto gap-10 mt-16">
        <div className=" shadow-2xl flex flex-col gap-28 rounded-b-xl p-6 h-fit border-t-8 border-blue-400 bg-white">
          <div className="flex flex-col gap-8">
            <h1 className="text-start text-4xl text-gray-600 font-semibold">
              One App Free
            </h1>
            <p className="text-start text-blue-400">
              <span className="text-4xl font-semibold relative -top-6">$</span>{" "}
              <span className="text-7xl font-semibold">0</span>
            </p>
            <p className="text-start text-gray-600 text-lg">
              For{" "}
              <span className="text-black font-semibold">one app only,</span>
              unlimited users <br />
              Odoo Online
            </p>
          </div>
          <div className="text-start">
            <button class="bg-blue-500 hover:bg-blue-700 text-white text-xl font-bold uppercase py-6 px-10 rounded w-full">
              Start Now
            </button>
          </div>
        </div>
        <div className=" shadow-2xl flex flex-col gap-28 rounded-b-xl p-6 h-fit border-t-8 border-red-400 bg-white">
          <div className="flex flex-col gap-8">
            <h1 className="text-start text-4xl text-gray-600 font-semibold">
              Standard
            </h1>
            <div>
              <div className="flex justify-between items-start ">
                <p className="text-start text-red-400">
                  <span className="text-4xl font-semibold relative -top-6">
                    $
                  </span>
                  <span className="text-7xl font-semibold">9</span>
                  <span className="text-4xl font-semibold relative -top-6">
                    .10
                  </span>
                </p>
                <s className="text-xl text-red-400 font-semibold relative">
                  <span className="text-black">US$ 11.20 *</span>
                </s>
              </div>
              <p className="text-base text-gray-500 font-semibold text-start relative left-16 -top-3">
                / user / month
              </p>
            </div>
            <p className="text-start text-gray-600 text-lg">
              <span className="text-black font-semibold">All apps</span>
              <br />
              Odoo Online
            </p>
          </div>
          <div className="text-start flex flex-col gap-4">
            <button class="bg-blue-500 hover:bg-blue-700 text-white text-xl font-bold uppercase py-6 px-10 rounded w-full">
              Buy Now
            </button>
            <button class="bg-gray-200 hover:bg-gray-300 text-xl font-bold uppercase py-6 px-10 rounded w-full text-blue-500">
              Free Trial
            </button>
          </div>
        </div>
        <div className=" shadow-2xl flex flex-col gap-7 rounded-b-xl p-6 h-fit border-t-8 border-teal-400 bg-white">
          <div className="flex flex-col gap-8">
            <h1 className="text-start text-4xl text-gray-600 font-semibold">
              Custom
            </h1>
            <div>
              <div className="flex justify-between items-start ">
                <p className="text-start text-teal-400">
                  <span className="text-4xl font-semibold relative -top-6">
                    $
                  </span>
                  <span className="text-7xl font-semibold">9</span>
                  <span className="text-4xl font-semibold relative -top-6">
                    .10
                  </span>
                </p>
                <s className="text-xl text-red-400 font-semibold relative">
                  <span className="text-black">US$ 11.20 *</span>
                </s>
              </div>
              <p className="text-base text-gray-500 font-semibold text-start relative left-16 -top-3">
                / user / month
              </p>
            </div>
            <p className="text-start text-gray-600 text-lg">
              <span className="text-black font-semibold">All apps</span> <br />
              Odoo Online / Odoo.sh** <br />
              Odoo Studio <br /> Multi-Company <br />
              External API
            </p>
          </div>
          <div className="text-start flex flex-col gap-4">
            <button class="bg-blue-500 hover:bg-blue-700 text-white text-xl font-bold uppercase py-6 px-10 rounded w-full">
              Buy Now
            </button>
            <button class="bg-gray-200 hover:bg-gray-300 text-xl font-bold uppercase py-6 px-10 rounded w-full text-blue-500">
              Free Trial
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCards;
