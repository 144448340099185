import React from "react";
import device_expenses from "../../assets/device_expenses.webp";

const Tech = () => {
  const elements = Array.from({ length: 7 }, (_, i) => i);

  return (
    <div className="flex flex-col gap-10 my-20">
      <h1 className="text-center font-extrabold text-5xl w-4/5 mx-auto my-20">
        All the tech in one platform
      </h1>
      <div className="flex flex-wrap justify-center gap-20 md:w-4/5 mx-auto">
        {elements.map((i) => (
          <div key={i} className="flex flex-col justify-center items-center">
            <div className="bg-gray-100 rounded-full">
              <img src={device_expenses} alt="device_expenses" />
            </div>
            <h3 className="text-xl font-semibold my-3">Expenses</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tech;
