import React from "react";
import ctrl_k_360 from "../../assets/ctrl-k-630.gif";
import speed_1 from "../../assets/speed_1.webp";
import speed_3 from "../../assets/speed_3.webp";
import speed_4 from "../../assets/speed_4.webp";
import mobile_productivity from "../../assets/mobile_productivity.webp";

const Productivity = () => {
  return (
    <div className="bg-gray-100 p-10 md:p-20 mb-20">
      <h1 className="text-center font-extrabold text-3xl md:text-5xl md:w-4/5 mx-auto lg:mb-40">
        Optimized for productivity
      </h1>
      <div class="wrap w-5/6 mx-auto hidden lg:block">
        <div class="grid grid-rows-12 grid-cols-12">
          <div class="row-span-full col-start-4 col-span-6 self-center">
            <img
              class="row-span-full object-cover p-3 bg-white rounded-xl w-full"
              src={speed_1}
              alt="speed_1"
            />
          </div>
          <div class="row-span-4 row-start-10 col-start-2 col-span-4 self-end">
            <img
              class="object-cover p-3 bg-white rounded-xl"
              src={ctrl_k_360}
              alt="ctrl_k_360"
            />
          </div>
          <div class="row-span-full col-span-6 col-start-9 self-end p-4 rounded-lg">
            <img
              class="object-cover  p-3 bg-white rounded-xl w-80"
              src={speed_3}
              alt="speed_3"
            />
          </div>
          <div class="row-span-full col-span-4 col-start-2 self-start p-4 rounded-lg">
            <img
              class="object-cover p-3 bg-white rounded-xl w-72"
              src={speed_4}
              alt="speed_4"
            />
          </div>
        </div>
      </div>
      <img
        src={mobile_productivity}
        alt="mobile_productivity"
        className="bg-white rounded-xl my-20 lg:hidden w-full"
      />
      <p className="text-2xl text-gray-500 lg:w-2/5 mx-auto">
        {/* <p className="lg:-mt-[55rem] text-2xl text-gray-500 lg:w-2/5 mx-auto"> */}
        <span className="font-bold text-black">Experience true speed,</span>{" "}
        reduced data entry, smart AI, and a fast UI. All operations are done in
        less than 90ms - faster than a blink.
      </p>
    </div>
  );
};

export default Productivity;
