import React from "react";
import Layout from "../layouts/Layout";
import Contact from "../components/Contact/Contact";

const ContactUs = () => {
  return (
    <div>
      <Layout>
        <Contact />
      </Layout>
    </div>
  );
};

export default ContactUs;
