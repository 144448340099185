import React, { useState } from "react";
import Card from "../Cards/Card";
import { FaToggleOff } from "react-icons/fa6";
import { FaToggleOn } from "react-icons/fa6";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const Service = () => {
  const elements = Array.from({ length: 24 }, (_, i) => i);
  const [toggle, setToggle] = useState(false);

  return (
    <div className="mt-20 bg-gray-100 py-40 rounded-t-[20rem]">
      <div className="grid grid-cols-3  md:grid-cols-4 lg:grid-cols-6 md:w-4/5 lg:w-3/5 mx-auto gap-y-5 gap-x-0">
        {elements.map((i) => (
          <div className="flex flex-col justify-center items-center" key={i}>
            <Card key={i} />
            <h3 className="font-semibold my-3">Accounting</h3>
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center px-10 md:px-0 md:w-3/5 mx-auto my-4">
        {toggle ? (
          <FaToggleOn
            className="text-5xl text-blue-400"
            onClick={() => setToggle(!toggle)}
          />
        ) : (
          <FaToggleOff
            className="text-5xl text-blue-400"
            onClick={() => setToggle(!toggle)}
          />
        )}
        <button class="flex items-center justify-center gap-2 text-blue-400 font-semibold text-lg">
          <span>View all apps</span>
          <MdOutlineKeyboardDoubleArrowRight className="text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default Service;
