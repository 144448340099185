import React from "react";
import { IoIosCall } from "react-icons/io";
import calender from "../../assets/calendar.svg";

const DirectContacts = () => {
  return (
    <div className="w-full p-4 md:p-0">
      <h1 className="text-start font-semibold text-4xl">Direct Contacts</h1>
      <p className="text-start text-gray-500 text-lg my-4">
        Call or Schedule a video conference
      </p>
      <p className="text-lg text-gray-500 flex items-center gap-2">
        <IoIosCall /> +32 2 290 34 90
      </p>
      <div className="flex flex-col gap-3 mt-5">
        <div className="flex items-center bg-gray-100 rounded-md py-3 pl-4 pr-16">
          <img src={calender} alt="calender" />
          <div>
            <h3 className="text-start text-gray-800 text-lg font-semibold">
              Schedule a demo
            </h3>
            <p className="text-start text-gray-600">
              Free, tailored, presentation by an expert
            </p>
          </div>
        </div>
        <div className="flex items-center bg-gray-100 rounded-md py-3 pl-4 pr-16">
          <img src={calender} alt="calender" />
          <div>
            <h3 className="text-start text-gray-800 text-lg font-semibold">
              Schedule a demo
            </h3>
            <p className="text-start text-gray-600">
              Free, tailored, presentation by an expert
            </p>
          </div>
        </div>
        <div className="flex items-center bg-gray-100 rounded-md py-3 pl-4 pr-16">
          <img src={calender} alt="calender" />
          <div>
            <h3 className="text-start text-gray-800 text-lg font-semibold">
              Schedule a demo
            </h3>
            <p className="text-start text-gray-600">
              Free, tailored, presentation by an expert
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectContacts;
