import React from "react";
import cardImg from "../../assets/icon.svg";

const Card = () => {
  return (
    <div className=" shadow-lg bg-red-100 w-20 p-4 ">
      <img src={cardImg} alt="cardImg" className="mx-auto" />
    </div>
  );
};

export default Card;
