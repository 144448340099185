import React from "react";
import be from "../../assets/be.png";
import { IoIosCall } from "react-icons/io";

const Offices = () => {
  return (
    <div className="bg-gray-100 py-20">
      <div className=" md:w-4/5 mx-auto px-4 md:px-0">
        <h1 className="text-start font-bold text-5xl">Odoo Offices</h1>
        <h3 className="text-start font-semibold text-3xl mt-16">
          Europe, Middle East, and Africa
        </h3>
        <hr className="my-10" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
          <div>
            <div className="flex items-center gap-4">
              <img src={be} alt="flag" className="w-6" />
              <p className="text-xl font-semibold text-gray-700">
                {" "}
                Belgium <span className="text-gray-500"> - FR</span>
              </p>
            </div>
            <div className="bg-white p-6 mt-5">
              <div className="flex flex-wrap gap-x-2 gap-y-1">
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
              </div>
              <div className="mt-8">
                <p className="text-start">
                  Rue du Laid Burniat 5 <br />
                  1348 Louvain-la-Neuve
                </p>
              </div>
              <div className="mt-5">
                <p className="text-lg text-gray-500 flex items-center gap-2">
                  <IoIosCall /> +32 2 290 34 90
                </p>
                <p className="text-lg text-gray-500 flex items-center gap-2">
                  <IoIosCall /> +32 2 290 34 90
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-4">
              <img src={be} alt="flag" className="w-6" />
              <p className="text-xl font-semibold text-gray-700">
                {" "}
                Belgium <span className="text-gray-500"> - FR</span>
              </p>
            </div>
            <div className="bg-white p-6 mt-5">
              <div className="flex flex-wrap gap-x-2 gap-y-1">
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
              </div>
              <div className="mt-8">
                <p className="text-start">
                  Rue du Laid Burniat 5 <br />
                  1348 Louvain-la-Neuve
                </p>
              </div>
              <div className="mt-5">
                <p className="text-lg text-gray-500 flex items-center gap-2">
                  <IoIosCall /> +32 2 290 34 90
                </p>
                <p className="text-lg text-gray-500 flex items-center gap-2">
                  <IoIosCall /> +32 2 290 34 90
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-4">
              <img src={be} alt="flag" className="w-6" />
              <p className="text-xl font-semibold text-gray-700">
                {" "}
                Belgium <span className="text-gray-500"> - FR</span>
              </p>
            </div>
            <div className="bg-white p-6 mt-5">
              <div className="flex flex-wrap gap-x-2 gap-y-1">
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
                <h3
                  type="button"
                  class="text-red-500 hover:text-white border border-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-md text-md uppercase px-4 py-1.5 text-center w-fit"
                >
                  Finance
                </h3>
              </div>
              <div className="mt-8">
                <p className="text-start">
                  Rue du Laid Burniat 5 <br />
                  1348 Louvain-la-Neuve
                </p>
              </div>
              <div className="mt-5">
                <p className="text-lg text-gray-500 flex items-center gap-2">
                  <IoIosCall /> +32 2 290 34 90
                </p>
                <p className="text-lg text-gray-500 flex items-center gap-2">
                  <IoIosCall /> +32 2 290 34 90
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offices;
