import React, { useEffect, useRef } from "react";

const Modal = ({ onClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      const navbar = document.getElementById("navbar");

      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !navbar.contains(event.target)
      ) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={modalRef}
      className="w-full delay-100 transition-all translate-x-0 translate-y-0 shadow-2xl bg-white"
    >
      <hr />
      <div className="grid grid-cols-4 py-10 content-center justify-items-center mx-28 gap-10">
        <div className="text-start flex flex-col gap-3">
          <h1 className="uppercase font-bold border-b-2 text-green-500 border-green-100 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-3">
          <h1 className="uppercase font-bold border-b-2 text-green-500 border-green-100 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-3">
          <h1 className="uppercase font-bold border-b-2 text-green-500 border-green-100 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-3">
          <h1 className="uppercase font-bold border-b-2 text-green-500 border-green-100 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-3">
          <h1 className="uppercase font-bold border-b-2 text-green-500 border-green-100 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-3">
          <h1 className="uppercase font-bold border-b-2 text-green-500 border-green-100 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-3">
          <h1 className="uppercase font-bold border-b-2 text-green-500 border-green-100 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
        </div>
        <div className="text-start flex flex-col gap-3">
          <h1 className="uppercase font-bold border-b-2 text-green-500 border-green-100 py-3 pr-40">
            Finance
          </h1>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
          <p className="text-sm">Accounting</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
