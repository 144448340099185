import React, { useState } from "react";
import Modal from "../Modal/Modal";
import { Link } from "react-router-dom";
import { RiMenu3Fill } from "react-icons/ri";
import NavModal from "./NavModal";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isToggle, setIsToggle] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="sticky md:top-0 z-50" id="navbar">
      <div className="flex items-center justify-between md:justify-around px-5 lg:px-0 mt-5 bg-white pt-3 pb-5">
        <div>
          <Link to="/" className="font-bold text-3xl">
            LOGO
          </Link>
        </div>
        <div className="hidden md:block">
          <ul class="flex justify-around items-center md:ms-10 lg:ms-32 gap-5">
            <li class="mr-6">
              <button
                class="font-semibold flex items-center justify-between"
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                Modules/Features{" "}
                <svg
                  class="w-2.5 h-2.5 ms-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
            </li>
            <li class="mr-6">
              <button
                class="font-semibold flex items-center justify-between"
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                Community{" "}
                <svg
                  class="w-2.5 h-2.5 ms-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
            </li>
            <li class="mr-6">
              <Link class="font-semibold" to="/pricing-plan">
                Pricing
              </Link>
            </li>
            <li class="mr-6">
              <Link class="font-semibold" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden md:block">
          <ul className="flex items-center justify-center">
            <li class="mr-3">
              <a
                class="inline-block border border-white rounded py-2 px-4 font-semibold"
                href="#"
              >
                Sign In
              </a>
            </li>
            <li class="mr-3">
              <a
                class="inline-block border border-blue-500 rounded py-2 px-4 bg-blue-500 text-white font-semibold"
                href="#"
              >
                Try it free
              </a>
            </li>
          </ul>
        </div>
        <div className="md:hidden">
          <button className="text-3xl" onClick={() => setIsToggle(!isToggle)}>
            <RiMenu3Fill />
          </button>
        </div>
      </div>
      <div>
        {/* <button>Open Modal</button> */}
        {isModalOpen && <Modal onClose={closeModal} />}
      </div>
      <div className={isToggle ? "block" : "hidden"}>
        <NavModal />
      </div>
    </div>
  );
};

export default Header;
