import React from "react";
import user from "../../assets/van_donink.webp";
import kpmg from "../../assets/kpmg_logo.png";
import quote from "../../assets/quote.png";

const User = () => {
  return (
    <div className="my-20">
      <img
        src={quote}
        alt="quote"
        className="w-16 relative md:top-5 md:left-48 hidden md:block"
      />
      <div className="bg-gray-100 mx-auto w-4/5 px-20 pt-20 md:flex rounded-lg">
        <p className="text-lg md:text-lg lg:text-2xl text-black font-semibold md:w-4/6 text-start">
          The processing time for accounting documents has been noticeably
          reduced, in certain cases even from 2 days to only 5 hours. As a
          result we can now focus on what matters: reporting and advising the
          client.
        </p>
        <div className="flex md:flex-col py-10 md:py-0 md:justify-center justify-between items-end gap-6 md:w-2/6 relative md:-top-28">
          <img
            src={user}
            alt="user"
            className=" rounded-full w-20 hidden md:block"
          />
          <div>
            <h3 className="text-lg font-semibold text-end">Harry Van Donink</h3>
            <h5 className=" text-end">CEO KPMG Belgium</h5>
          </div>
          <img
            src={kpmg}
            alt="kpmg"
            className="w-28 bg-white px-5 py-3 shadow-xl rounded-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default User;
